import React from 'react';
import { FormControl, FormControlLabel, Box, Stack } from '@mui/material';
import { useRecoilState } from 'recoil';
import { OrderPayload, validOnboardingForm, toggleModal, isSonarQubeRepoAvailable, urlParams } from 'pages/project-onboarding/store';
import ProjectOnboardingStyles, { CustomRadioGroup, CustomRadio } from 'pages/project-onboarding/style';
import { resetOrder, resetValidOnboardingForm, resetToggleModal } from 'pages/project-onboarding/utils';

const AvailableADOProject = () => {
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [params] = useRecoilState(urlParams);
    const [, setValidProvisionForm] = useRecoilState(validOnboardingForm);
    const [, setToggleModal] = useRecoilState(toggleModal);
    const [, setRepoAvailable] = useRecoilState(isSonarQubeRepoAvailable);

    const setAvailableADOProject = (isADOProjectVal = 'no') => {
        const { sector, adoPlatform, isADOProject, courseDetails, ...rest } = resetOrder;
        if (params?.journeyId === null) {
            setOrder({
                ...order,
                isADOProject: isADOProjectVal,
                existingAdminMembers: [],
                isServiceAccountExists: false,
                serviceAccount: null,
                onboardRepositories: false,
                ...rest,
                serviceResources: {
                    ...order.serviceResources,
                    isProjectAdmin: false,
                    isBoard: false,
                    isRepos: false,
                    isPipelines: false,
                    isSonarQube: false,
                    isArtifactory: false,
                    isSecurityTools: false,
                    isTestPlan: false,
                },
            });
            setValidProvisionForm(resetValidOnboardingForm);
        } else {
            setOrder({
                ...order,
                isADOProject: isADOProjectVal,
                existingAdminMembers: [],
                isClarityId: null,
                clarityId: null,
                clarityDetails: null,
            });
            setValidProvisionForm((prevState) => ({
                ...prevState,
                ...resetValidOnboardingForm,
                isInvalidServiceAccount: prevState.isInvalidServiceAccount,
            }));
        }

        setToggleModal(resetToggleModal);
        setRepoAvailable(true);
    };

    const handleOnSelectChange = (selected) => {
        setAvailableADOProject(selected);
    };
    const getValidate = () => {
        if (order?.adoPlatform === 'Production' && !order?.isADOProject && order?.isTouched) return 'ritmCheckbox';
        return '';
    };

    return (
        <Stack direction="row" spacing={1} sx={ProjectOnboardingStyles.stack}>
            <FormControl sx={{ marginLeft: '5px', height: '36px', justifyContent: 'center', width: '100%' }}>
                <Box
                    sx={
                        order?.adoPlatform !== 'Production'
                            ? { ...ProjectOnboardingStyles.radioFields.radioText, opacity: '0.5' }
                            : { ...ProjectOnboardingStyles.radioFields.radioText, color: '#0047BA', fontWeight: 700 }
                    }
                    id="autoinflateRadioGrp"
                >
                    Do you have an ADO Project?
                </Box>
                <CustomRadioGroup
                    data-testid="available-ado-project"
                    aria-label="availableADOProject"
                    name="availableADOProjectRadioBtnGroup"
                    row
                    value={order?.isADOProject || null}
                    onChange={(event, value) => handleOnSelectChange(value)}
                    className={getValidate()}
                >
                    <FormControlLabel
                        value="yes"
                        control={<CustomRadio className={getValidate()} disabled={order?.adoPlatform !== 'Production'} />}
                        label="Yes"
                    />
                    <FormControlLabel
                        value="no"
                        control={<CustomRadio className={getValidate()} disabled={order?.adoPlatform !== 'Production'} />}
                        label="No"
                    />
                </CustomRadioGroup>
            </FormControl>
        </Stack>
    );
};

export default React.memo(AvailableADOProject);
