import { atom } from 'recoil';

export const sspSubmitOrder = atom({
    key: 'plb-sspOrder',
    default: {
        securityScanCompleted: 'no',
        appProfile: null,
        region: null,
        appProfileDetails: null,
        appOwner: null,
        appOwnerDetails: null,
        certificateOwner1: null,
        certificateOwner1Details: null,
        certificateOwner2: null,
        certificateOwner2Details: null,
        loadBalancerUrl: null,
        loadBalancerFrontEndPort: null,
        hCode: null,
        hCodeDetails: null,
        entityDetails: null,
        environment: null,
        envShortName: null,
        regionDisplayname: null,
        appTier: null,
        appTierCode: null,
        isaNumber: null,
        scanNumber: null,
        deletedFiles: [],
        applicationName: null,
        sslEncryption: 'yes',
        supportBackendCertificate: null,
        sector: null,
        sectorDetails: null,
        tags: [],
        adGroup: null,
        appSupportMailId: null,
        provisioning: false,
        estimatedCost: {
            details: [],
            totalRecurringCost: {
                frequency: 'monthly',
                estCost: null,
                currency: 'USD',
            },
            notes: 'There is no cost for the Public Load balancer URL onboarding in the shared Application Gateway from DevX Infra. It is included in shared service of your resource group. However, one time cost applicable for the procurement of entrust Standard OV certificate part of automation process.',
        },
        catalogCode: null,
        placeNewOrder: new Date(),
        validationStatus: 'clearValidation',
        isTouched: false,
    },
});

export const uploadedFilesState = atom({
    key: 'plb-uploadedFilesState',
    default: [],
});

export const environmentDetails = atom({
    key: 'plb-environmentDetails',
    default: {
        Subscription: '---',
        Resource_Group: '---',
        'Public Load Balancer Name': '---',
    },
});

export const isValidApplicationName = atom({
    key: 'plb-validApplicationName',
    default: false,
});
export const isValidPort = atom({
    key: 'plb-validPort',
    default: false,
});
export const isLoadBalancerUrlValid = atom({
    key: 'plb-isLoadBalancerUrlValid',
    default: false,
});
export const isValidBackendHealthProbe = atom({
    key: 'plb-validBackENdHealthProbe',
    default: false,
});
export const isValidFqdns = atom({
    key: 'plb-isValidFqdns',
    default: false,
});
export const isValidISANumber = atom({
    key: 'plb-isValidISANumber',
    default: false,
});
export const isValidScanNumber = atom({
    key: 'plb-isValidScanNumber',
    default: false,
});
export const toggleSspModels = atom({
    key: 'plb-toggleSspModels',
    default: {
        isCost: { costType: '', isCostOpen: false },
        isTagOpen: false,
        isApplicationOpen: false,
        isSubmitOrderSuccess: false,
        isUpdatedToCart: false,
        isCostSplitOpen: false,
    },
});

export const isMandatoryTagsFullfilled = atom({
    key: 'plb-isMandatoryTagsFullfilled',
    default: false,
});

export const isEnvDetailsLoading = atom({
    key: 'plb-isEnvDetailsLoading',
    default: false,
});
export const costEstimateState = atom({
    key: 'plb-costEstimateState',
    default: null,
});
export const isLoadingCostApiState = atom({
    key: 'plb-isLoadingCostApiState',
    default: {
        isLoading: false,
    },
});

export const orderId = atom({
    key: 'plb-orderId',
    default: '',
});

export const isValidappSupportMailId = atom({
    key: 'plb-isValidappSupportMailId',
    default: false,
});

export const isValidgtmLoadBalancer = atom({
    key: 'plb-isValidgtmLoadBalancer',
    default: false,
});

export const isValidOwners = atom({
    key: 'plb-isValidOwners',
    default: {
        isvalidAppOwner: true,
        isvalidCertificateOwner1: true,
        isvalidCertificateOwner2: true,
    },
});
