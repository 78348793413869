import React from 'react';
import { FormControl, FormControlLabel, Box, Stack } from '@mui/material';
import { useRecoilState } from 'recoil';
import { OrderPayload, validOnboardingForm, toggleModal, isSonarQubeRepoAvailable, urlParams } from 'pages/project-onboarding/store';
import ProjectOnboardingStyles, { CustomRadioGroup, CustomRadio } from 'pages/project-onboarding/style';
import { resetOrder, resetValidOnboardingForm, resetToggleModal } from 'pages/project-onboarding/utils';

const AvailableClarityId = () => {
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [params] = useRecoilState(urlParams);
    const [, setValidProvisionForm] = useRecoilState(validOnboardingForm);
    const [, setToggleModal] = useRecoilState(toggleModal);
    const [, setRepoAvailable] = useRecoilState(isSonarQubeRepoAvailable);

    const setAvailableClarityId = (isClarityIdVal = 'no') => {
        const { sector, adoPlatform, isADOProject, isClarityId, courseDetails, ...rest } = resetOrder;

        if (params?.journeyId === null) {
            setOrder({
                ...order,
                isClarityId: isClarityIdVal,
                ...rest,
            });
            setValidProvisionForm(resetValidOnboardingForm);
        } else {
            setOrder({
                ...order,
                isClarityId: isClarityIdVal,
                clarityId: null,
                clarityDetails: null,
            });
            setValidProvisionForm((prevState) => ({
                ...prevState,
                ...resetValidOnboardingForm,
                isInvalidServiceAccount: prevState.isInvalidServiceAccount,
            }));
        }
        setToggleModal(resetToggleModal);
        setRepoAvailable(true);
    };

    const handleOnSelectChange = (selected) => {
        setAvailableClarityId(selected);
    };
    const getValidate = () => {
        if (order?.isADOProject === 'no' && !order?.isClarityId && order?.isTouched) return 'ritmCheckbox';
        return '';
    };

    return (
        <Stack direction="row" spacing={1} sx={ProjectOnboardingStyles.stack}>
            <FormControl sx={{ marginLeft: '5px', height: '36px', justifyContent: 'center', width: '100%' }}>
                <Box
                    sx={
                        order?.isADOProject !== 'no'
                            ? { ...ProjectOnboardingStyles.radioFields.radioText, opacity: '0.5' }
                            : { ...ProjectOnboardingStyles.radioFields.radioText, color: '#0047BA', fontWeight: 700 }
                    }
                    id="autoinflateRadioGrp"
                >
                    Do you have a Clarity ID?
                </Box>
                <CustomRadioGroup
                    data-testid="available-clarity-id"
                    aria-label="availableClarityId"
                    name="availableClarityIdRadioBtnGroup"
                    row
                    value={order?.isClarityId || null}
                    onChange={(event, value) => handleOnSelectChange(value)}
                    className={getValidate()}
                >
                    <FormControlLabel value="yes" control={<CustomRadio className={getValidate()} disabled={order?.isADOProject !== 'no'} />} label="Yes" />
                    <FormControlLabel value="no" control={<CustomRadio className={getValidate()} disabled={order?.isADOProject !== 'no'} />} label="No" />
                </CustomRadioGroup>
            </FormControl>
        </Stack>
    );
};

export default React.memo(AvailableClarityId);
