import { camelToTitle } from 'utils';

export const formOrderObjFromOrderDetails = (catalogServiceId = null, data = null) => {
    const entityDetails = data?.entityDetails || null;
    const catalog = data?.catalogServices?.find((item) => item?.catalogServiceId === catalogServiceId) || null;
    const catalogConfig = catalog?.catalogConfig || null;
    const environmentDetails = catalogConfig?.environmentDetails || null;
    return {
        ...(data?.id && { id: data?.id }),
        securityScanCompleted: 'yes',
        // Application Section
        appProfile: entityDetails?.name,
        ...(entityDetails?.name && { appProfileDetails: { label: entityDetails?.name, value: entityDetails?.name } }),
        ...(!entityDetails?.name && { appProfileDetails: null }),
        entityDetails: {
            ...entityDetails,
            owner: { name: entityDetails?.applicationManager, emailId: entityDetails?.applicationManagerEmail, id: entityDetails?.applicationManagerGpid },
            financialDetails: catalogConfig?.budgetCode,
            sector: catalogConfig?.sector,
        },
        applicationName: catalogConfig?.applicationName,
        sector: catalogConfig?.sector || null,
        region: catalogConfig?.region || null,
        regionDisplayname: catalogConfig?.region,
        environment: catalogConfig?.environment || null,
        hCode: catalogConfig?.budgetCode || null,
        ...(environmentDetails?.costCenter && {
            hCodeDetails: { label: environmentDetails?.costCenter, value: environmentDetails?.costCenter, projectName: environmentDetails?.projectName },
        }),
        appOwner: data?.manager?.id,
        appOwnerDetails: null,
        certificateOwner1: null,
        certificateOwner1Details: { gpid: catalogConfig?.certificateOwnerOne },
        certificateOwner2: null,
        certificateOwner2Details: { gpid: catalogConfig?.certificateOwnerTwo },
        adGroup: catalogConfig?.adGroup,
        appSupportMailId: catalogConfig?.appSupportMailId,
        dataClassification: catalogConfig?.dataClassification,
        // Resource Section
        loadBalancerUrl: catalogConfig?.loadBalancerUrl,
        loadBalancerFrontEndPort: catalogConfig?.loadBalancerFrontEndPort,
        sslEncryption: catalogConfig?.sslEncryption ? 'yes' : 'no',
        supportBackendCertificate: catalogConfig?.supportBackendCertificate,
        // Environment Section
        environmentDetails,
        // common Details
        catalogCode: catalog?.catalogServiceCode,
        catalogType: catalog?.catalogType,
        cloudProvider: catalog?.cloudProvider,
        cloudService: catalog?.cloudService,
        isaNumber: catalogConfig?.isaNumber || catalogConfig?.scanNumber,
        ...(!environmentDetails?.costCenter && { hCodeDetails: null }),
        isaValidation: true,
        isaValidationDetails: null,
        provisioning: true,
        validationStatus: 'clearValidation',
        isTouched: false,
    };
};

export const environmentConfig = {
    development: {
        regex: /^[a-zA-Z0-9-]{3,36}\.dev\.(mypepsico|pepsico)\.com$/,
        message: `Load Balancer URL is Invalid. Must be in the format: xxx.dev.mypepsico.com or xxx.dev.pepsico.com (where xxx is 3-36 characters and should not contain any '.' value)`,
    },
    qa: {
        regex: /^[a-zA-Z0-9-]{3,36}\.(qa|ite)\.(mypepsico|pepsico)\.com$/,
        message: `Load Balancer URL is Invalid. Must be in the format: xxx.qa.mypepsico.com or xxx.qa.pepsico.com or xxx.ite.mypepsico.com or xxx.ite.pepsico.com (where xxx is 3-36 characters and should not contain any '.' value)`,
    },
    'pre-production': {
        regex: /^[a-zA-Z0-9-]{3,36}\.(preprod|ite)\.(mypepsico|pepsico)\.com$/,
        message: `Load Balancer URL is Invalid. Must be in the format: xxx.preprod.mypepsico.com or xxx.ite.mypepsico.com (where xxx is 3-36 characters and should not contain any '.' value)`,
    },
    production: {
        regex: /^[a-zA-Z0-9-]{3,36}\.(mypepsico|pepsico)\.com$/,
        message: `Load Balancer URL is Invalid. Must be in the format: xxx.mypepsico.com or xxx.pepsico.com (where xxx is 3-36 characters and should not contain any '.' value)`,
    },
};

export const validateAppURL = (url, environment) => {
    const config = environmentConfig[environment?.toLowerCase()];

    if (!config) {
        return {
            isValid: false,
            message: 'Invalid environment specified',
        };
    }

    const isValid = config.regex.test(url);
    return {
        isValid,
        message: isValid ? 'Valid URL' : config.message,
    };
};

export const resetOrder = {
    securityScanCompleted: 'no',
    appProfile: null,
    appProfileDetails: null,
    applicationName: null,
    sector: null,
    sectorDetails: null,
    region: null,
    regionDisplayname: null,
    environment: null,
    envShortName: null,
    hCode: null,
    hCodeDetails: null,
    appOwner: null,
    appOwnerDetails: null,
    certificateOwner1: null,
    certificateOwner1Details: null,
    certificateOwner2: null,
    certificateOwner2Details: null,
    adGroup: null,
    dataClassification: null,
    appSupportMailId: null,
    loadBalancerUrl: null,
    loadBalancerFrontEndPort: null,
    sslEncryption: 'yes',
    supportBackendCertificate: null,
    entityDetails: null,
    isaNumber: null,
    tags: [],
    estimatedCost: null,
    isaValidation: null,
    isaValidationMessage: null,
    isaValidationDetails: null,
    provisioning: false,
    placeNewOrder: new Date(),
    validationStatus: 'clearValidation',
    isTouched: false,
};

export const resetCostEstimateDetails = {
    totalRecurringCost: {
        frequency: 'monthly',
        estCost: 0,
        currency: 'USD',
    },
    totalOneTimeCost: {
        frequency: 'One Time',
        estCost: 0,
        currency: 'USD',
    },

    notes: 'There is no cost for the Public Load balancer URL onboarding in the shared Application Gateway from DevX Infra. It is included in shared service of your resource group. However, one time cost applicable for the procurement of entrust Standard OV certificate part of automation process.',
};

export const resetEnvironmentDetails = {
    Subscription: '---',
    Resource_Group: '---',
    'Public Load Balancer Name': '---',
};

export const resetToggleSspModal = {
    isCost: { costType: '', isCostOpen: false },
    isTagOpen: false,
    isApplicationOpen: false,
    isSubmitOrderSuccess: false,
    isCostSplitOpen: false,
};
export const isUniqueOwner = (value = null, selectedvalues = []) => {
    return selectedvalues.indexOf(value) === -1;
};
export const getValueFromKey = (searchKey, keyValuePairs) => {
    return keyValuePairs[searchKey] ? keyValuePairs[searchKey] : camelToTitle(searchKey);
};
export const mappingLabel = {
    isaNumber: 'ISA Number',
    isaStatus: 'ISA Status',
    parentIsaNumber: 'Parent ISA Number',
    parentIsaNumberStatus: 'Parent ISA Number Status',
    scanNumber: 'SCAN Number',
    scanStatus: 'SCAN Status',
    scanTaskNumber: 'SCAN Task Number',
    scanTaskState: 'SCAN Task State',
};
