import React from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress } from '@mui/material';
import { useRecoilState } from 'recoil';
import { OrderPayload, toggleModal } from 'pages/project-onboarding/store';
// import ProjectOnboardingStyles from 'pages/project-onboarding/style';
import { commonStyles as style } from 'assets/style';
import Btn from 'components/button';

const AddButton = ({ handleAddApplication, isLoading }) => {
    const [order] = useRecoilState(OrderPayload);
    const [isOpen] = useRecoilState(toggleModal);

    const isDisabled = () => {
        let isFlag = false;
        if (isOpen?.isFortifySnykOpen) {
            isFlag = !order?.sastApplicationName || !order?.sastVersion;
        }
        return isFlag;
    };

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '60px' }}>
            <Btn
                size="small"
                onClick={() => handleAddApplication()}
                variant="contained"
                disabled={isDisabled()}
                // sx={ProjectOnboardingStyles?.addBtn}
                sx={{ ...style.primaryMediumButton, width: '150px' }}
                // color="cmpPrimary"
                data-testid="add-member-btn"
                className="btn-disabled"
            >
                {isLoading ? <CircularProgress color="inherit" size={16} /> : 'Add'}
            </Btn>
        </Box>
    );
};

AddButton.propTypes = {
    handleAddApplication: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
};

AddButton.defaultProps = {
    isLoading: false,
};

export default React.memo(AddButton);
