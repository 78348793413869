import React from 'react';
import { Box, TableContainer, Table, TableBody, Typography, Divider } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
// import DeleteIcon from '@mui/icons-material/Delete';
import deleteIcon from 'assets/images/delete_icon.svg';
import { useRecoilState } from 'recoil';
import { OrderPayload, validOnboardingForm } from 'pages/project-onboarding/store';
import ProjectOnboardingStyles from 'pages/project-onboarding/style';
import InfoTooltip from 'pages/project-onboarding/components/info-tooltip';
import AdminAccessMember from 'pages/project-onboarding/components/Form/ServiceMembers/AdminAccessMember';
import ServiceDialog from './ServiceDialog';

const ProjectAdminConfiguration = () => {
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [isValidForm, setValidForm] = useRecoilState(validOnboardingForm);
    const [deleteMember, setDeleteMember] = React.useState(null);
    const [isOpen, setIsOpen] = React.useState(false);

    const handleDeleteMember = () => {
        if (order?.adminAccessMemberDetails) {
            if (deleteMember?.gpid === order?.adminAccessMemberDetails?.gpid) {
                setOrder({
                    ...order,
                    adminAccessMemberList: null,
                    adminAccessMemberDetails: null,
                });
                setValidForm({ ...isValidForm, isDuplicateAdminAccessMember: false });
            }
        }
        setOrder({
            ...order,
            adminAccessMemberList: order?.adminAccessMemberList?.filter((item) => item.gpid !== deleteMember?.gpid),
        });
        setValidForm({ ...isValidForm, isAdminAccessMaxMemberLimit: false });
    };

    const handleNo = () => {
        setIsOpen(false);
    };
    const handleYes = () => {
        handleDeleteMember();
        setIsOpen(false);
    };

    return (
        <>
            <Box data-testid="project-admin-config">
                <AdminAccessMember />
                <Box display="flex" mt={2} gap={1}>
                    <Typography variant="h6" sx={ProjectOnboardingStyles.configSubHeader}>
                        Admin Access Members *
                    </Typography>
                    <InfoTooltip title="As per the PepsiCo DevOps compliance policy, the Project Administrator needs to complete the mandatory DevOps Training within a week time otherwise the access to Project administrator will be revoked." />
                </Box>
                <Divider />
                <Box display="flex">
                    {order?.adminAccessMemberList?.length > 0 ? (
                        <Box width="100%">
                            <TableContainer>
                                <Table size="small">
                                    <TableBody>
                                        {order?.adminAccessMemberList &&
                                            order?.adminAccessMemberList?.length &&
                                            order?.adminAccessMemberList?.map((item, index) => (
                                                <TableRow key={index}>
                                                    <TableCell align="left" width="1">
                                                        <Typography>{item?.gpid || ''}</Typography>
                                                    </TableCell>
                                                    <TableCell align="left" width="1">
                                                        {item?.name || '-'}
                                                    </TableCell>
                                                    <TableCell align="left" width="1">
                                                        {item?.email === 'null' || !item?.email ? '-' : item?.email}
                                                    </TableCell>
                                                    <TableCell
                                                        width="1"
                                                        onClick={() => {
                                                            setDeleteMember(item);
                                                            setIsOpen(true);
                                                        }}
                                                    >
                                                        {/* <DeleteIcon
                                                            data-testid="delete-member-btn"
                                                            sx={ProjectOnboardingStyles?.removeBtn}
                                                            color="error"
                                                            onClick={() => {
                                                                setDeleteMember(item);
                                                                setIsOpen(true);
                                                            }}
                                                            className="animate-opacity"
                                                        /> */}
                                                        <img style={ProjectOnboardingStyles?.removeBtn} src={deleteIcon} alt="deleteIcon" />
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    ) : (
                        <Box mt={2}>
                            <Typography variant="body1">No Members Selected</Typography>
                        </Box>
                    )}
                </Box>

                <Box display="flex" alignItems="baseline" justifyContent="space-between" mt={2}>
                    {order?.adminAccessMemberList && order?.adminAccessMemberList?.length > 0 ? (
                        <Typography>
                            <b>{order?.adminAccessMemberList?.length}</b> {order?.adminAccessMemberList?.length < 2 ? 'Member' : 'Members'} Selected{' '}
                        </Typography>
                    ) : (
                        <>&nbsp;</>
                    )}
                </Box>
            </Box>
            <ServiceDialog isOpen={isOpen} member={deleteMember?.name} handleNo={handleNo} handleYes={handleYes} />
        </>
    );
};

export default React.memo(ProjectAdminConfiguration);
